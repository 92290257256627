//new color variables
$grey-medium: #939598;
$grey-light: #d9d9d9;
$grey-dark: #58595b;
$blue: #0078be;
$blue-light: #00b3f0;
$blue-light-tint: #e6f8fe;
$purple: #6801af;
$purple-light: #bb64ff;
$orange: #ff560b;
$orange-light: #ffa608;
$green: #298c00;
$green-light: #a9ce21;
$white: #ffffff;
$aqua: #18ffff;
$aqua-green: #00ffc2;




// your _variables.scss file
$primary-blue: #0078d7;
$secondary-blue: #1d87df;
$light-blue: #e6ecfe;
$light-blue-2: #b4c7ff;
$light-blue-3: #e6ecfe;
$light-blue-4: #dfeeff;
$light-blue-5: #b0d5ff;
$white: #ffffff;
$gray: #f1f1f1;
$gray-1: #f7f7f7;
$gray-2: #cccccc;
$gray-3: #666666;
$black: #000000;
$black-1: #262626;
$border-color: #e5e5e5;
$bg-shadow-1: #00000033;
$bg-shadow-2: #00000030;
$bg-color-1: #003cbe;
$bg-color-2: #07eeff;
$bg-color-3: #7200be;
$bg-color-4: #d08dfd;
$bg-color-5: #07b9ff;
$bg-color-6: #1f0164;
$bg-color-7: #288cff;
$btn-animation-duration: 0.6s;
$card-animation-duration: 0.3s;





// new design

$blue-dark: #0078be;
$blue-light: #00b3f0;
$purple-dark: #6801af;
$purple-light: #bb64ff;
$orange: #ff560b;
$yellow: #ffa608;
$green-dark: #298c00;
$green-light: #a9ce21;
$black-dark: #58595b;
$black-light: #939598;
$white: #ffffff;
$black: #000000;