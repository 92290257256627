
@import "./stylings/variables";


@font-face {
    font-family: 'Gill Sans medium';
    src: url('./assets/fonts/Gill\ Sans\ Medium.otf') format('opentype');
}

@font-face {
    font-family: 'Gill Sans';
    src: url('./assets/fonts/Gill\ Sans.otf') format('opentype');
}

@font-face {
    font-family: 'Gill Sans semi-bold';
    src: url('./assets//fonts/Gill-Sans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Gill Sans bold';
    src: url('./assets/fonts/Gill\ Sans\ Bold.otf') format('opentype')
}

@font-face {
    font-family: 'Gill Sans light';
    src: url('./assets/fonts/Gill\ Sans\ Light.otf') format('opentype')
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Gill Sans', sans-serif;
    font-weight: normal;
}

.fw-light {
    font-family: 'Gill Sans light', sans-serif !important;
}

.fw-bold {
    font-family: 'Gill Sans medium', sans-serif !important;
}

.fw-bolder {
    font-family: 'Gill Sans bold', sans-serif !important;
}

.w- {
    &100 {
        width: 100%;
    }
    &90 {
        width: 90%;
    }

    &80 {
        width: 80%;
    }

    &70 {
        width: 70%;
    }

    &60 {
        width: 60%;
    }

    &50 {
        width: 50%;
    }

    &40 {
        width: 40%;
    }

    &30 {
        width: 30%;
    }

    &20 {
        width: 20%;
    }

    &10 {
        width: 10%;
    }
}

.h- {
    &100 {
        height: 100%;
    }
    &90 {
        height: 90%;
    }

    &80 {
        height: 80%;
    }

    &70 {
        height: 70%;
    }

    &60 {
        height: 60%;
    }

    &50 {
        height: 50%;
    }

    &40 {
        height: 40%;
    }

    &30 {
        height: 30%;
    }

    &20 {
        height: 20%;
    }

    &10 {
        height: 10%;
    }
}


@keyframes floating {
    from {
        transform: translate(0, 0px);
    }

    65% {
        transform: translate(0, -5px);
    }

    to {
        transform: translate(0, 0px);
    }
}

.animate- {
    &bob {
        animation-name: floating;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }
}

.clr {
    &-blue-dark {
        color: $blue-dark !important;
    }
    &-blue-light {
        color: $blue-light !important;
    }
    &-purple-dark {
        color: $purple-dark !important;
    }
    &-purple-light {
        color: $purple-light;
    }
    &-orange {
        color: $orange !important;
    }
    &-yellow {
        color: $yellow !important;
    }
    &-green-dark {
        color: $green-dark !important;
    }
    &-green-light {
        color: $green-light !important;
    }
    &-black-dark {
        color: $black-dark !important;
    }
    &-black-light {
        color: $black-light !important;
    }
    &-white {
        color: $white !important;
    }
}

.bg-clr {
    &-blue-dark {
        background-color: $blue-dark !important;
    }
    &-blue-light {
        background-color: $blue-light !important;
    }
    &-purple-dark {
        background-color: $purple-dark !important;
    }
    &-purple-light {
        background-color: $purple-light !important;
    }
    &-orange {
        background-color: $orange !important;
    }
    &-yellow {
        background-color: $yellow !important;
    }
    &-green-dark {
        background-color: $green-dark !important;
    }
    &-green-light {
        background-color: $green-light !important;
    }
    &-black-dark {
        background-color: $black-dark !important;
    }
    &-black-light {
        background-color: $black-light !important;
    }
    &-white {
        background-color: $white !important;
    }
}


.hover-popup {
    transition: .3s;
    &:hover {
        transform: scale(1.12);
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {  }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    html {
        font-size: 0.688rem; // 11px
    }
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    html {
        // font-size: 0.875rem; //14px
        font-size: 0.813rem; //13px
        // font-size: 0.75rem; //12px
    }
 }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
    html {
        font-size: 0.938rem; // 15px
        // font-size: 0.875rem; //14px
    }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 
    html {
        font-size: 1rem; // 16px
    }
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab{
    position: relative !important;
}

.mat-mdc-tab-label-container{
    border-bottom-style: none !important;
    border-bottom: none !important;
}

.mdc-tab__text-label {
    margin-bottom: 0.5rem !important;
    font-size: 1rem !important;
}

.mat-mdc-tab .mdc-tab__text-label{
    color: black !important;
}

// .mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination{
//     background-color: #4d4b4b !important;
// }

.mat-mdc-tab-header-pagination-chevron {
    border-color: black !important;;
}